import './App.sass';

import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { createBrowserHistory } from 'history';

import Header from './components/header/Header';
//import Footer from './pages/footer/Footer';
import Sidebar from './components/sidebar/Sidebar';
import Dashboard from './pages/dashboard/Dashboard';
import Reports from './pages/dashboard/reports/Reports';
import Profile from './pages/dashboard/profile/Profile';
import Support from './pages/dashboard/support/Support';
import UserManagement from './pages/dashboard/user-management/UserManagement';
import UserEdit from './pages/dashboard/user-management/user-edit/UserEdit';
import Login from './pages/auth/login/Login';
import Register from './pages/auth/register/Register';
import ForgotPassword from './pages/auth/forgot-password/ForgotPassword';
import ResetPassword from './pages/auth/reset-password/ResetPassword';
import Verify from './pages/auth/verify/Verify';

const history = createBrowserHistory();

function App() {
  const [isDashboard, setIsDashboard] = React.useState(false)

  const routerChange = () => {
    if(window.location.pathname.includes('/dashboard')) {
      setIsDashboard(true);
    }
  }

  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  return (
    <Router>
    <div className="App">
        <Switch>
          <Route exact path={['/','/login']}>
            <Login />
          </Route>
          <Route path='/register'>
            <Register />
          </Route>
          <Route path='/forgot-password'>
            <ForgotPassword />
          </Route>
          <Route path='/reset-password'>
            <ResetPassword />
          </Route>
          <Route path='/verify'>
            <Verify />
          </Route>
          <Route
             path="/dashboard"
             render={({ match: { url } }) => (
               <>
                <Route path={`${url}/`}>
                <Header />
                <Sidebar />
                </Route>
                 <Route path={`${url}/`} exact onChange={routerChange} >
                  <Dashboard/>
                 </Route>
                 <Route path={`${url}/reports`} component={Reports} />
                 <Route path={`${url}/profile`} component={Profile} />
                 <Route path={`${url}/support`} component={Support} />
                 <Route path={`${url}/user-management`} exact component={UserManagement} />
                 <Route path={`${url}/user-management/edit-user`} component={UserEdit}/>
               </>
             )}
           />

        </Switch>
    </div>
    </Router>
  );
}

export default App;
